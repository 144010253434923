
import { defineComponent, onMounted, reactive, Ref, ref, watch } from 'vue';
import { VueDraggableNext } from 'vue-draggable-next';
import { SortableEvent } from 'sortablejs';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';

import Layout from '@/components/Layout.vue';
import Loader from '@/components/Loader.vue';
import EditTaskOverlay from '@/components/project/tasks/EditOverlay.vue';
import AddTaskOverlay from '@/components/project/tasks/AddOverlay.vue';

import Store from '@/store';
import TaskService from '@/services/task.service';
import Task from '@/models/task.model';
import useEmitter from '@/compostables/useEmitter';
import TaskStatus from '@/models/task-status.model';
import TaskGroupService from '@/services/task-group.service';
import TaskGroup from '@/models/task-group.model';
import User from '@/models/user.model';
import ProjectService from '@/services/project.service';
import { useRoute } from 'vue-router';

interface StatusObject extends TaskStatus {
	tasks: Task[];
}

interface UserObject extends User {
	tasks: Task[];
}

export default defineComponent({
	components: {
		Layout,
		Loader,
		EditTaskOverlay,
		AddTaskOverlay,
		draggable: VueDraggableNext,
	},

	setup() {
		const route = useRoute();

		const members: Ref<User[]> = ref([]);
		const tasks = Store.get('tasks');
		const statusTasks: Ref<StatusObject[]> = ref([]);
		const responsibleTasks: Ref<UserObject[]> = ref([]);
		const statuses = Store.get('statuses');
		const active = ref('status');

		const groups: Ref<TaskGroup[]> = ref([]);

		const addOverlay = reactive({
			active: false,
			status: null as number | null,
			responsible: null as number | null,
		});

		const projectId = ref(0);
		const editOverlayOpen = ref(false);

		const loading = ref(true);

		onMounted(async () => {
			Store.set('header.nav', [
				{
					title: 'Listi',
					name: 'ProjectTasks',
				},
				{
					title: 'Kanban',
					name: 'ProjectTasksCards',
				},
			]);

			const emitter = useEmitter();

			await ProjectService.show(Number(route.params.id)).then((project) => {
				if (project) {
					Store.set('tasks', project.tasks);
					projectId.value = project.id;
					members.value = project.members;
				}
			});

			handleStatusTasks();
			handleResponsibleTasks();

			// Close Overlay on escape
			emitter.on('escape', () => {
				editOverlayOpen.value = false;
				addOverlay.active = false;
			});

			loading.value = false;
		});

		watch(tasks, () => {
			handleStatusTasks();
			handleResponsibleTasks();
		});

		watch(projectId, async (projectId) => {
			if (projectId) {
				await TaskGroupService.get(projectId).then((response) => {
					if (response) {
						groups.value = response;
					}
				});
			}
		});

		// Open the Edit Task overlay
		function openEditOverlay(task: Task) {
			const user = Store.get('user');

			if (!user.value.can('update tasks')) {
				return;
			}

			Store.set('task', task);

			editOverlayOpen.value = true;
		}

		// Open the Add Task overlay
		function openAddOverlay(type: string, id: number) {
			const user = Store.get('user');

			if (!user.value.can('create tasks')) {
				return;
			}

			addOverlay.status = type === 'status' ? id : null;
			addOverlay.responsible = type === 'status' ? null : id;
			addOverlay.active = true;
		}

		// Sort tasks by status
		function handleStatusTasks() {
			let statusObject: StatusObject[] = [];

			statuses.value.forEach((status: TaskStatus) => {
				statusObject.push({
					...status,
					tasks: [],
				});
			});

			tasks.value.forEach((task: Task) => {
				if (typeof task.task_status_id === 'number') {
					const statusIndex = statusObject.findIndex(
						(status: TaskStatus) => status.id === task.task_status_id
					);

					if (statusObject[statusIndex]) {
						statusObject[statusIndex].tasks.push(task);
					}
				}
			});

			statusObject = _map(statusObject, (status) => {
				status.tasks = _sortBy(status.tasks, 'responsible_user_id');
				return status;
			});

			statusTasks.value = statusObject;
		}

		// Sort tasks by responsible person
		function handleResponsibleTasks() {
			let responsibleObject: UserObject[] = [];

			responsibleObject.push({
				first_name: 'AAA',
				last_name: 'Eingin',
				id: 0,
				can: () => {
					return false;
				},
				cannot: () => {
					return false;
				},
				tasks: [],
			});

			members.value.forEach((user: User) => {
				if (user.member?.role != 'MANAGER' && user.member?.role != 'USER') {
					return;
				}

				responsibleObject.push({
					...user,
					tasks: [],
				});
			});

			responsibleObject = _uniqBy(responsibleObject, 'id');

			tasks.value.forEach((task: Task) => {
				const responsibleIndex = responsibleObject.findIndex(
					(responsible: User) => responsible.id === task.responsible_user_id
				);

				if (responsibleIndex === -1) {
					responsibleObject[0].tasks.push(task);
				} else {
					responsibleObject[responsibleIndex].tasks.push(task);
				}
			});

			responsibleObject = _sortBy(responsibleObject, 'first_name');

			responsibleObject = _map(responsibleObject, (responsible) => {
				responsible.tasks = _sortBy(responsible.tasks, 'task_status_id');
				return responsible;
			});

			responsibleTasks.value = responsibleObject;
		}

		// Functions for handling move between lists
		async function addToStatusList(event: SortableEvent, statusId: number) {
			const itemId = Number(event.item.id);

			const taskIndex = tasks.value.findIndex((task: Task) => task.id === itemId);
			const task = tasks.value.find((task: Task) => task.id === itemId);

			if (typeof task.title === 'string') {
				await TaskService.update(itemId, { title: task.title, task_status_id: statusId }).then((response) => {
					if (response) {
						task.task_status_id = statusId;

						tasks.value.splice(taskIndex, 1, task);

						handleStatusTasks();
					}
				});
			}
		}

		async function addToPersonList(event: SortableEvent, personId: number) {
			const itemId = Number(event.item.id);

			const taskIndex = tasks.value.findIndex((task: Task) => task.id === itemId);
			const task = tasks.value.find((task: Task) => task.id === itemId);

			if (typeof task.title === 'string') {
				await TaskService.update(itemId, {
					title: task.title,
					responsible_user_id: personId === 0 ? null : personId,
				}).then((response) => {
					if (response) {
						task.responsible_user_id = personId === 0 ? null : personId;

						tasks.value.splice(taskIndex, 1, task);

						handleResponsibleTasks();
					}
				});
			}
		}

		function getStatus(id: number) {
			const status = statuses.value.find((status: TaskStatus) => status.id === id);

			return status?.title || '';
		}

		return {
			// data
			tasks,
			loading,
			active,
			statusTasks,
			responsibleTasks,
			editOverlayOpen,
			projectId,
			addOverlay,
			groups,

			// functions
			addToStatusList,
			addToPersonList,
			openEditOverlay,
			openAddOverlay,
			getStatus,
		};
	},
});
